import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // $('#modalNavidad').modal('show');
    // setTimeout(function () {
    //   $('#modalNavidad').modal('hide');
    // }, 15000);
  }

}
