<app-menu></app-menu>
<div class="container-fluid px-0" id="cont-nuevos">
    <div class="row m-0">
        <div class="col-12 text-white mt-5">
            <h1 class="text-center text-black" id="titulo-seccion">FORMAS DE PAGO</h1>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-xl-10 offset-xl-1">
            <div class="row mt-5">
                <div class="col">
                    <p class="text-center">
                        - <b>Efectivo en pesos</b> (al cambio del dolar blue del momento del pago).<br><br>
                        - <b>Efectivo en dolar billete</b> (se toman cara grande o banda azul, no se toman cara
                        chica ni
                        manchados).<br><br>
                        - <b>Transferencia</b> en pesos +4%.<br><br>
                        - <b>Criptomonedas</b> +2,5%
                    </p>
                </div>
            </div>
        </div>
    </div>
    <app-pie-pagina></app-pie-pagina>