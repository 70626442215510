<app-menu-admin></app-menu-admin>
<div class="container-fluid h-100">
    <div class="row">
        <div class="col-md-4 offset-md-4 mt-5">
            <h4 class="text-center text-white">Opciones</h4>
            <form action="" method="post" class="mt-4" (submit)="guardarConfig()">
                <div class="form-group">
                    <label class="text-white" for=""><b>Cotización dolar blue: ${{dolarArg}}</b></label>
                    <br>
                    <label for="" class="text-white">Mostrar precios en $</label>
                    <select class="form-control" [(ngModel)]="pesos" name="pesos" id="pesos">
                    <option value="1">Si</option>
                    <option value="0">No</option>
                  </select>
                    <button type="submit" class="btn mt-4">Modificar</button>
                </div>
            </form>
        </div>
    </div>
</div>