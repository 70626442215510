<app-menu></app-menu>
<div class="container-fluid px-0" id="cont-promos">
    <div class="row m-0">
        <div class="col-12 text-white">
            <h1 class="text-center text-black mt-5 mb-4" id="titulo-seccion">¡<span class="tipo-modelo">SOLO </span>POR HOY!</h1>
        </div>
    </div>
    <div class="row m-0">
        <div *ngFor="let promo of promos" class="col-md-4 mb-4">
            <div *ngIf="promo.estado == 1">
                <img src="data:image/jpeg;base64,{{promo.foto}}" class="img-fluid" alt="">
                <a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20consultar%20una%20promoción%20que%20ví%20en%20su%20página%20web." target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
            </div>
        </div>
    </div>
    <!-- <!-- <a class="boton-promos shadow" href="#" routerLink="/promos" title="¡Ver promociones!"><i class="fas fa-percent fa-lg" style="margin-top: 5px;"></i></a> --> -->
    <app-pie-pagina></app-pie-pagina>