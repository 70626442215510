import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iphones-usados',
  templateUrl: './iphones-usados.component.html',
  styleUrls: ['./iphones-usados.component.css']
})
export class IphonesUsadosComponent implements OnInit {

  usados;
  usadoSeleccionado;
  color: string;
  bateria: number;
  pantalla: number;
  interna: number;
  precio: number;
  orden: number;
  oferta: number;
  lugarEntrega: string;
  descripcion: string;
  tipo:string;

  colorUsado: string;
  bateriaUsado: number;
  pantallaUsado: number;
  internaUsado: number = 128;
  precioUsado: number;
  ordenUsado: number;
  ofertaUsado: number;
  lugarUsado: string = "La Plata";
  descripcionUsado: string = "";
  tipoUsado: string;

  formModificar: boolean;
  formNuevo: boolean;
  tablaUsados: boolean;

  resultado: boolean;
  resultadoNuevo: boolean;

  constructor(private http: HttpClient) {
    this.resultado = false;
  }

  traerUsados() {
    this.http.get('https://likeiphone.com.ar/api/traerCelularesUsadosTodos.php').subscribe((res: any) => {
      // console.log(res)
      this.usados = res
    })
  }

  traerSesion() {
    this.http.get('https://likeiphone.com.ar/api/traerSesion.php').subscribe((acceso: any) => {
      if (acceso != 1) {
        window.location.href = './#/admin/acceso';
      }
    })
  }


  eliminar(idCelular: number) {
    document.getElementById("btn-eliminar-" + idCelular).style.display = "none";
    document.getElementById("btn-modificar-" + idCelular).style.display = "none";
    document.getElementById("loading-" + idCelular).style.display = "block";
    document.getElementById("loading-" + idCelular).style.margin = "auto";
    this.http.post('https://likeiphone.com.ar/api/eliminarIphoneUsado.php', idCelular)
      .subscribe((result: any) => {
        this.resultado = result.res;
        if (this.resultado) {
          location.reload();
        }
      })
  }

  buscar(idCelular: number) {
    //alert("modificar "+idCelular);
    this.usados.forEach(usado => {
      if (usado.idUsado == idCelular) {
        this.usadoSeleccionado = usado;
      }
    });
    //  console.log(this.usadoSeleccionado);
    this.color = this.usadoSeleccionado.color;
    this.bateria = this.usadoSeleccionado.bateria;
    this.pantalla = this.usadoSeleccionado.pantalla;
    this.precio = this.usadoSeleccionado.precio;
    this.interna = this.usadoSeleccionado.interna;
    this.lugarEntrega = this.usadoSeleccionado.lugarEntrega.trim();
    this.descripcion = this.usadoSeleccionado.descripcion;
    this.tipo = this.usadoSeleccionado.tipo;
    this.formModificar = true;
    this.tablaUsados = false;
  }


  modificar() {
    this.usadoSeleccionado.color = this.color;
    this.usadoSeleccionado.bateria = this.bateria;
    this.usadoSeleccionado.precio = this.precio;
    this.usadoSeleccionado.pantalla = this.pantalla;
    this.usadoSeleccionado.interna = this.interna;
    this.usadoSeleccionado.descripcion = this.descripcion;
    this.usadoSeleccionado.tipo = this.tipo;
    this.usadoSeleccionado.lugarEntrega = this.lugarEntrega.trim();
    // console.log(this.usadoSeleccionado);
    // alert("Modifico:"+this.usadoSeleccionado.idUsado);
    this.http.post('https://likeiphone.com.ar/api/modificarIphoneUsado.php', this.usadoSeleccionado
    ).subscribe((result: any) => {
      this.resultado = result.res;
      if (this.resultado == true) {
        this.formModificar = false;
        setTimeout(() => {
          location.reload();
          this.resultado = false;
          this.tablaUsados = true;
        }, 1000);
      }
    })
  }

  nuevo() {
    this.formNuevo = true;
    this.tablaUsados = false;
  }

  subirNuevo() {
    var usado = new Object();
    usado['color'] = this.colorUsado;
    usado['bateria'] = this.bateriaUsado;
    usado['pantalla'] = this.pantallaUsado;
    usado['precio'] = this.precioUsado;
    usado['interna'] = this.internaUsado;
    usado['oferta'] = 0;
    usado['orden'] = this.ordenUsado;
    usado['lugar'] = this.lugarUsado;
    usado['descripcion'] = this.descripcionUsado;
    usado['tipo'] = this.tipoUsado;
    this.http.post('https://likeiphone.com.ar/api/nuevoUsado.php', usado, { responseType: 'json' })
      .subscribe((result: any) => {
        this.resultadoNuevo = result.res;
        if (this.resultadoNuevo) {
          this.colorUsado = "";
          this.bateriaUsado = null;
          this.pantallaUsado = null;
          this.internaUsado = 128;
          this.precioUsado = null;
          this.ordenUsado = null;
          this.ofertaUsado = null;
          this.lugarUsado = "La Plata";
          this.formNuevo = false;
          this.descripcionUsado = "";
          this.tipoUsado = "";
          setTimeout(() => {
            location.reload();
            this.resultadoNuevo = false;
            this.tablaUsados = true;
          }, 1000);
        }
      })
  }

  volver() {
    this.formModificar = false;
    this.formNuevo = false;
    this.tablaUsados = true;
  }

  ngOnInit(): void {
    this.traerSesion();
    this.traerUsados();
    this.formModificar = false;
    this.formNuevo = false;
    this.tablaUsados = true;
  }

}
