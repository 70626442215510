<app-menu></app-menu>
<div class="container-fluid" id="cont-info">
    <div class="row">
        <div class="col-12">
            <h1 class="text-center text-black mt-5 mb-4" id="titulo-seccion">MÁS <span class="tipo-modelo"> INFO</span>
            </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <div id="accordianId" role="tablist" aria-multiselectable="true">
                <div class="card">
                    <div class="card-header" role="tab" id="section1HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId"
                                href="#section1ContentId" aria-expanded="true" aria-controls="section1ContentId">
                                ¿Que métodos de pago aceptan?
                            </a>
                        </h5>
                    </div>
                    <div id="section1ContentId" class="collapse in" role="tabpanel" aria-labelledby="section1HeaderId">
                        <div class="card-body">
                            - <b>Efectivo en pesos</b> (al cambio del dolar blue del momento del pago).<br><br>
                            - <b>Efectivo en dolar billete</b> (se toman cara grande o banda azul, no se toman cara
                            chica ni
                            manchados).<br><br>
                            - <b>Transferencia</b> en pesos +4%.<br><br>
                            - <b>Criptomonedas</b> +2,5%
                        </div>
                    </div>
                </div>
                <!--<div class="card">
                    <div class="card-header" role="tab" id="section2HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId" href="#section2ContentId" aria-expanded="true" aria-controls="section2ContentId">
                       ¿Se puede pagar en cuotas?
                    </a>
                        </h5>
                    </div>
                    <div id="section2ContentId" class="collapse in" role="tabpanel" aria-labelledby="section2HeaderId">
                        <div class="card-body">
                            <p class="m-0 mb-2">
                            Sí, se puede pagar en 3, 6 o 12 cuotas. Los precios se calculan automaticamente por el sistema en pesos.
                            Podes mirar a cuanto te queda cada cuota en el siguiente enlace:
                            </p>
                            <p><a href="nuevos-iphone" class="enlace">Ver iPhone nuevos</a></p>
                            <p><a href="usados-iphone" class="enlace">Ver iPhone usados</a></p>
                        </div>
                    </div>
                </div>-->
                <div class="card">
                    <div class="card-header" role="tab" id="section3HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId"
                                href="#section3ContentId" aria-expanded="true" aria-controls="section2ContentId">
                                ¿Tienen garantia los iPhone?
                            </a>
                        </h5>
                    </div>
                    <div id="section3ContentId" class="collapse in" role="tabpanel" aria-labelledby="section3HeaderId">
                        <div class="card-body">
                            Sí, todos nuestros equipos tienen garantía.
                            <br> - Los iPhone nuevos tienen 1 año de garantía oficial Apple.
                            <br> - Los iPhone usados tienen 60 días de garantía.
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" role="tab" id="section4HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId"
                                href="#section4ContentId" aria-expanded="true" aria-controls="section2ContentId">
                                ¿Toman mi iPhone como parte de pago?
                            </a>
                        </h5>
                    </div>
                    <div id="section4ContentId" class="collapse in" role="tabpanel" aria-labelledby="section4HeaderId">
                        <div class="card-body">
                            Aceptamos desde iPhone 8 o superior como parte de pago. El celular es revisado por un
                            técnico que determinará el precio en que se cotiza el equipo. Igualmente podes saber una
                            cotización aproximada de tu equipo presionando el siguiente enlace:
                            <p class="mt-4"><a class="enlace" routerLink="/cotizar-mi-iphone">Cotizar mi iPhone</a></p>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" role="tab" id="section5HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId"
                                href="#section5ContentId" aria-expanded="true" aria-controls="section2ContentId">
                                ¿Se puede pagar con varios métodos de pago?
                            </a>
                        </h5>
                    </div>
                    <div id="section5ContentId" class="collapse in" role="tabpanel" aria-labelledby="section5HeaderId">
                        <div class="card-body">
                            Si, se puede pagar combinando métodos de pago.
                            <br> Por ejemplo: Puede entregar su equipo y efectivo.
                            <br> Otro ejemplo: Puede abonar una parte en efectivo en pesos y la diferencia restante en
                            dolares.
                            <br>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" role="tab" id="section6HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId"
                                href="#section6ContentId" aria-expanded="true" aria-controls="section2ContentId">
                                ¿Como realizo una compra?
                            </a>
                        </h5>
                    </div>
                    <div id="section6ContentId" class="collapse in" role="tabpanel" aria-labelledby="section6HeaderId">
                        <div class="card-body">
                            <p class="mt-4 mb-2">Para realizar una compra contáctanos en el siguiente enlace vía
                                WhatsApp, ahí mismo coordinamos una cita en nuestra oficina para organizar los detalles
                                de compra.</p>
                            <br>
                            <a href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20coordinar%20una%20cita%20en%20la%20oficina."
                                target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Coordinar
                                cita!</a>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" role="tab" id="section7HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId"
                                href="#section7ContentId" aria-expanded="true" aria-controls="section2ContentId">
                                ¿Realizan envíos a todo el país?
                            </a>
                        </h5>
                    </div>
                    <div id="section7ContentId" class="collapse in" role="tabpanel" aria-labelledby="section7HeaderId">
                        <div class="card-body">
                            Sí, realizamos envíos a todo el país. Nuestra oficina esta ubicada en la ciudad de La
                            Plata.<br>
                            <p class="mt-4"><a class="enlace"
                                    href="https://www.google.com.ar/maps/place/Plaza+Olaz%C3%A1bal+137,+B1900+La+Plata,+Provincia+de+Buenos+Aires/@-34.9062225,-57.9616776,21z/data=!4m5!3m4!1s0x95a2e7b335476315:0xac41e8d9657ad0bd!8m2!3d-34.9062148!4d-57.9615777"
                                    target="_blank">Plaza Olazábal 137</a></p>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" role="tab" id="section9HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId"
                                href="#section9ContentId" aria-expanded="true" aria-controls="section2ContentId">
                                ¿Donde están ubicados?
                            </a>
                        </h5>
                    </div>
                    <div id="section9ContentId" class="collapse in" role="tabpanel" aria-labelledby="section9HeaderId">
                        <div class="card-body">
                            Nos encontramos en:
                            <br> - <i class="fas fa-map-marker-alt crojo"></i> Oficina: Plaza Olazábal 137 - La Plata
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" role="tab" id="section8HeaderId">
                        <h5 class="mb-0 text-center fw300">
                            <a class="cnegro" data-toggle="collapse" data-parent="#accordianId"
                                href="#section8ContentId" aria-expanded="true" aria-controls="section2ContentId">
                                ¿Venden accesorios Iphone?
                            </a>
                        </h5>
                    </div>
                    <div id="section8ContentId" class="collapse in" role="tabpanel" aria-labelledby="section8HeaderId">
                        <div class="card-body">
                            <p>Sí, vendemos. Para saber y consultar los productos contactarse por WhatsApp.</p>
                            <br>
                            <a href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20"
                                target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Hablar vía
                                Whatsapp!</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <a class="boton-promos shadow" href="#" routerLink="/promos" title="¡Ver promociones!"><i class="fas fa-percent fa-lg" style="margin-top: 5px;"></i></a> -->
<app-pie-pagina></app-pie-pagina>