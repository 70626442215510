<app-menu></app-menu>
<div class="container-fluid" id="cont-cotizar">
    <div class="row">
        <div class="col-12 text-white">
            <h1 class="text-center text-black mt-5 mb-5" id="titulo-seccion">COTIZAR <span class="tipo-modelo">MI
                    IPHONE</span></h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h5 class="text-center">Aceptamos dispositivos desde el iPhone 11 en adelante como parte de pago.</h5>
        </div>
        <div class="col-md-6 offset-md-3 mt-4">
            <table class="table">
                <thead>
                    <th><i class="fas fa-mobile-alt" style="color: #ec0024;"></i> Modelo</th>
                    <!-- <th><i class="fas fa-mobile-alt" style="color: #ec0024;"></i> Valor</th> -->
                    <th><i class="fas fa-dollar-sign" style="color: #ec0024;"></i> Cotización</th>
                </thead>
                <tbody>
                    <tr *ngFor="let c of cotizacion">
                        <td>{{c.modelo}}</td>
                        <!-- <td>APROX. USD{{c.precio}}</td> -->
                        <td><a ngClass="d-block btn btn-cotizar"
                                href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20cotizar%20mi%20IPHONE%20{{c.modelo}}"
                                target="_blank" class="whatsapp w-100"><i class="fab fa-whatsapp fa-lg"></i>
                                ¡Consultar!</a></td>
                    </tr>
                </tbody>
            </table>
            <!-- <div class="form-group text-center">
                <label for="miiphone">¿Que Iphone tenés?</label>
                <select class="form-control mt-3 mb-5" [(ngModel)]="modeloAcotizar" name="miiphone" id="miiphone" (change)="cotizar()">
                  <option value="{{cotizacion.modelo}}"  *ngFor="let cotizacion of cotizacion">IPHONE {{cotizacion.modelo}}</option>
                </select>
            </div>
            <h1 class="text-center mt-5 fw200" id="iphone-seleccionado" [ngStyle]="{'display':'none'}"></h1>
            <h1 class="text-center fw200">APROXIMADO = <span class="crojo fw200">USD{{precioCotizacion}}</span></h1>
            <button class="mx-auto btn mt-3 mb-3" id="btn-cotizar" (click)="nuevaCotizacion()" [ngStyle]="{'display':'none'}">Cotizar nuevamente</button> -->
            <div class="mb-5 mt-5">
                <p>Los valores son ESTIMATIVOS, el valor final se define únicamente en nuestras oficinas viendo el
                    equipo por los técnicos.</p>
                <p>SI el equipo presenta daños, se descuenta del valor detallado a continuación.</p>
                <p>Los equipos deben entregarse lo mas completos posibles, CAJA, CARGADOR, AURICULARES, ETC.</p>
                <p>Los equipos indistintamente de la capacidad que tengan se cotizan igual.</p>
            </div>
        </div>
    </div>
</div>
<!-- <a class="boton-promos shadow" href="#" routerLink="/promos" title="¡Ver promociones!"><i class="fas fa-percent fa-lg" style="margin-top: 5px;"></i></a> -->
<app-pie-pagina></app-pie-pagina>