<nav class="navbar navbar-expand-lg navbar-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03"
        aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars text-white"></i>
    </button>
    <a class="navbar-brand" href="#">
        <img src="../../assets/img/logoTrans.png" height="50" alt="">
    </a>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item">
                <a class="nav-link" href="/#/admin/iphones-nuevos"> <i class="fas fa-mobile-alt"></i> IPHONES NUEVOS</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/#/admin/iphones-usados"> <i class="fas fa-mobile-alt"></i> IPHONES USADOS</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/#/admin/reparaciones"> <i class=" fas fa-tools "></i> REPARACIONES</a>
            </li>
            <!-- li class="nav-item ">
                <a class="nav-link " href="/#/admin/cotizaciones "><i class="fas fa-balance-scale-left "></i> COTIZACIONES</a>
            </li>
            <li class="nav-item ">
                <a class="nav-link " href="/#/admin/importaciones "> <i class="fas fa-globe-americas "></i> IMPORTACIONES</a>
            </li> -->
            <li class="nav-item ">
                <a class="nav-link " href="/#/admin/opciones "> <i class="fas fa-wrench "></i> OPCIONES</a>
            </li>
            <li class="nav-item ">
                <a class="nav-link " href="/#/admin/acceso "> <i class="fas fa-sign-out-alt "></i> SALIR</a>
            </li>
        </ul>
    </div>
</nav>