<div class="container-fluid">
    <div class="row vh-100">
        <div class="d-flex justify-content-center align-items-center w-100">
            <form action="" id="form-login">
                <img src="../../assets/img/logoTrans.png" class="d-block mx-auto mb-4" height="80" alt="">
                <div class="form-group">
                    <label for="">Usuario</label>
                    <input [(ngModel)]="usuario" type="text" class="form-control mb-4 mt-2" name="usuario" id="usuario" aria-describedby="helpId" placeholder="">
                    <small id="helpId" class="form-text text-muted"></small>
                </div>
                <div class="form-group">
                    <label for="">Clave</label>
                    <input [(ngModel)]='clave' type="password" class="form-control mb-4" name="clave" id="clave" placeholder="">
                </div>
                <p *ngIf="logueo==false" class="text-center text-danger">¡Datos incorrectos!</p>
                <button (click)="login()" type="submit" class="btn btn-primary mt-4 mx-auto" style="display: block;" id="btn-entrar">Entrar</button>
                <img src="../../../assets/img/loading.gif" id="loading" height="30" class="mx-auto mt-4" style="display: none;" alt="">
            </form>
        </div>
    </div>
</div>