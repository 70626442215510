import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boton-contacto',
  templateUrl: './boton-contacto.component.html',
  styleUrls: ['./boton-contacto.component.css']
})
export class BotonContactoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
