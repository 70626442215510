import { PromoService } from './promo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.css']
})
export class PromosComponent implements OnInit {

  public promos;

  constructor(public promo: PromoService) {
    this.promo.getPromos('https://likeiphone.com.ar/api/traerPromos.php').subscribe((res: any) => {
      this.promos = res;
      console.log(this.promos);
    });
  }

  ngOnInit(): void {
  }

}
