<app-menu></app-menu>
<div class="container-fluid" id="cont-reparacion">
    <div class="row">
        <div class="col-md-6 offset-md-3 mt-5 py-4" style="background-color: black;">
            <h4 class="text-center text-white bgblack fw300 mb-4"><i class="fas fa-clock crojo"></i> Horario</h4>
            <h6 class="text-center text-white">Lunes a Viernes de 10:00 a 13:00Hs y 16:00 a 19:00Hs</h6>
            <h6 class="text-center text-white">Sábados de 11:00 a 14:00Hs</h6>
            <h6 class="text-center text-white mt-4"><i class="fas fa-map-marker-alt crojo"></i> Dirección: 7 N°106 casi 34</h6>
        </div>
    </div>
    <div class="row mt-4" *ngIf="filtrarArreglo('modulo', reparaciones) > 0">
        <div class="col-12">
            <h1 class="text-center fw200"><i class="fas fa-mobile-alt crojo"></i> MÓDULOS</h1>
        </div>
        <div class="col-xl-10 offset-xl-1 animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <!-- <th><i class="fas fa-dollar-sign"></i> VALOR</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let reparacion of reparaciones">
                            <tr *ngIf="reparacion.tipoArreglo=='modulo' && reparacion.precio!='0'">
                                <td>IPHONE {{reparacion.modelo}}</td>
                                <!-- <td>${{reparacion.precio | number:'1.0'}} (USD{{reparacion.precio/dolarArg | number:'1.0'}})</td> -->
                                <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20arreglar%20mi%20{{reparacion.tipoArreglo}}%20%20de%20un%20IPHONE%20{{reparacion.modelo}}" target="_blank"
                                        class="whatsapp w-100"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a></td>
                            </tr>
                        </ng-container>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="filtrarArreglo('bateria', reparaciones) > 0">
        <div class="col-12">
            <h1 class="text-center fw200"><i class="fas fa-battery-three-quarters crojo"></i> BATERÍAS</h1>
        </div>
        <div class="col-xl-10 offset-xl-1 animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <!-- <th><i class="fas fa-dollar-sign"></i> VALOR</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let reparacion of reparaciones">
                            <tr *ngIf="reparacion.tipoArreglo=='bateria' && reparacion.precio!='0'">
                                <td>IPHONE {{reparacion.modelo}}</td>
                                <!-- <td>${{reparacion.precio | number:'1.0'}} (USD{{reparacion.precio/dolarArg | number:'1.0'}})</td> -->
                                <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20arreglar%20mi%20{{reparacion.tipoArreglo}}%20%20de%20un%20IPHONE%20{{reparacion.modelo}}" target="_blank"
                                        class="whatsapp w-100"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a></td>
                            </tr>
                        </ng-container>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="filtrarArreglo('pin', reparaciones) > 0">
        <div class="col-12">
            <h1 class="text-center fw200"><i class="fab fa-usb crojo"></i> PÍN DE CARGA</h1>
        </div>
        <div class="col-xl-10 offset-xl-1 animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <!-- <th><i class="fas fa-dollar-sign"></i> VALOR</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let reparacion of reparaciones">
                            <tr *ngIf="reparacion.tipoArreglo=='pin' && reparacion.precio!='0'">
                                <td>IPHONE {{reparacion.modelo}}</td>
                                <!-- <td *ngIf="reparacion.precio>0">${{reparacion.precio | number:'1.0' }} (USD{{reparacion.precio/dolarArg | number:'1.0'}})</td> -->
                                <!-- <td *ngIf="reparacion.precio==-1"> -->
                                <!-- <a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20consultar%20cuanto%20cuesta%20la%20reparación%20del%20pin%20de%20carga%20para%20 -->
                                <!-- el%20modelo%20IPHONE%20{{reparacion.modelo}}"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a> -->
                                <!-- </td> -->
                                <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20arreglar%20mi%20{{reparacion.tipoArreglo}}%20%20de%20un%20IPHONE%20{{reparacion.modelo}}" target="_blank"
                                        class="whatsapp w-100"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a></td>
                            </tr>
                        </ng-container>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="filtrarArreglo('tapa', reparaciones) > 0">
        <div class="col-12">
            <h1 class="text-center fw200"><i class="fas fa-mobile-alt crojo"></i> TAPA TRASERA</h1>
        </div>
        <div class="col-xl-10 offset-xl-1 animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <!-- <th><i class="fas fa-dollar-sign"></i> VALOR</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let reparacion of reparaciones">
                            <tr *ngIf="reparacion.tipoArreglo=='tapa' && reparacion.precio!='0'">
                                <td>IPHONE {{reparacion.modelo}}</td>
                                <!-- <td>${{reparacion.precio | number:'1.0'}} (USD{{reparacion.precio/dolarArg | number:'1.0'}})</td> -->
                                <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20arreglar%20mi%20{{reparacion.tipoArreglo}}%20%20de%20un%20IPHONE%20{{reparacion.modelo}}" target="_blank"
                                        class="whatsapp w-100"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a></td>
                            </tr>
                        </ng-container>
                </table>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="filtrarArreglo('home', reparaciones) > 0">
        <div class="col-12">
            <h1 class="text-center fw200"><i class="fas fa-tools crojo"></i> BOTÓN HOME</h1>
        </div>
        <div class="col-xl-10 offset-xl-1 animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <!-- <th><i class="fas fa-dollar-sign"></i> VALOR</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let reparacion of reparaciones">
                            <tr *ngIf="reparacion.tipoArreglo=='home' && reparacion.precio!='0'">
                                <td>IPHONE {{reparacion.modelo}}</td>
                                <!-- <td>${{reparacion.precio | number:'1.0' }} (USD{{reparacion.precio/dolarArg | number:'1.0'}})</td> -->
                                <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20arreglar%20mi%20{{reparacion.tipoArreglo}}%20%20de%20un%20IPHONE%20{{reparacion.modelo}}" target="_blank"
                                        class="whatsapp w-100"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a></td>
                            </tr>
                        </ng-container>
                </table>
            </div>
        </div>
    </div>

    <!-- <div class="row" *ngIf="filtrarArreglo('camara', reparaciones) > 0">
        <div class="col-12">
            <h1 class="text-center fw200"><i class="fas fa-camera crojo"></i> CÁMARA TRASERA</h1>
        </div>
        <div class="col-xl-10 offset-xl-1 animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let reparacion of reparaciones">
                            <tr *ngIf="reparacion.tipoArreglo=='camara' && reparacion.precio!='0'">
                                <td>IPHONE {{reparacion.modelo}}</td>
                                <td>${{reparacion.precio | number:'1.0' }} (USD{{reparacion.precio/dolarArg | number:'1.0'}})</td>
                                <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20arreglar%20mi%20{{reparacion.tipoArreglo}}%20%20de%20un%20IPHONE%20{{reparacion.modelo}}%20que%20sale%20${{reparacion.precio | number:'1.0'}}."
                                        target="_blank" class="whatsapp w-100"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a></td>
                            </tr>
                        </ng-container>
                </table>
            </div>
        </div>
    </div> -->

</div>
<!-- <a class="boton-promos shadow" href="#" routerLink="/promos" title="¡Ver promociones!"><i class="fas fa-percent fa-lg" style="margin-top: 5px;"></i></a> -->
<app-pie-pagina></app-pie-pagina>