import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  usuario: string = "";
  clave: string = "";
  logueo:boolean;

  

  constructor(private http:HttpClient) {

  }

  login() {
    document.getElementById('btn-entrar').style.display = "none";
    document.getElementById('loading').style.display = "block";
    this.http.post('https://likeiphone.com.ar/api/verificarAcceso.php',{
      usuario:this.usuario,
      clave:this.clave
    }).toPromise().then((data:boolean)=>{
      this.logueo=data['res']
      if(this.logueo==true){
        window.location.href='./#/admin/iphones-usados/'
      }else{
        document.getElementById('btn-entrar').style.display = "block";
        document.getElementById('loading').style.display = "none";
      }
    });
  }



  ngOnInit(): void {
    
   
  }

}
