import { Component, OnInit } from '@angular/core';
import { ImportacionesService } from './importaciones.service';

@Component({
  selector: 'app-importaciones',
  templateUrl: './importaciones.component.html',
  styleUrls: ['./importaciones.component.css']
})
export class ImportacionesComponent implements OnInit {

  dolarArg=0;
  importacionesNuevas;
  configuracion;

  constructor(public importaciones:ImportacionesService) {

    this.importaciones.getJson('https://dolarapi.com/v1/dolares/blue').subscribe((res:any) => {
      this.dolarArg=parseFloat(res['venta'])+40;
    });

    this.importaciones.getImportaciones('https://likeiphone.com.ar/api/traerImportaciones.php').subscribe((res:any) => {
      this.importacionesNuevas=res;
      console.log(this.importacionesNuevas);
    });

    this.importaciones.getImportaciones('https://likeiphone.com.ar/api/traerConfiguracion.php').subscribe((res: any) => {
      this.configuracion = res;
    });

  }

  ngOnInit(): void {
  }

}
