import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  constructor(private http: HttpClient) {  }

  getJson(url: string){
    console.log(this.http.get(url));
    return this.http.get(url);
  }

  getPromos(url: string){
    console.log(this.http.get(url, {responseType: 'text'} ));
    return this.http.get(url);
  }

}
