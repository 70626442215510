import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReparacionesService {

  constructor(private http: HttpClient) { }

  getJson(url: string){
    console.log(this.http.get(url));
    return this.http.get(url);
  }
  
  getReparaciones(url: string){
    return this.http.get(url);
  }

}
