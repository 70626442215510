<app-menu></app-menu>
<div class="container-fluid" id="cont-importaciones">
    <div class="row">
        <div class="col-12 text-white">
            <!-- <h1 class="text-center text-black mt-5" id="titulo-seccion">IMPORTACIONES</h1> -->
            <img class="d-block mx-auto mt-5" src="../assets/img/logo-importaciones.png" alt="" height="80">
        </div>
    </div>
    <div class="row">
        <div class="col-xl-10 offset-xl-1">
            <div class="row">
                <div class="col-md-4" *ngFor="let imp of importacionesNuevas">
                    <div class="pt-2 cont-producto">
                        <img class="d-block mx-auto" src="../../assets/Ipads/{{imp.foto}}" height="200" width="100%" [ngStyle]="{'object-fit':'contain'}" alt="">
                        <h4 class="text-center fw300 mt-5 mb-4">{{imp.nombre}}</h4>
                        <p class="text-center"><span class="dolares-precio">USD{{imp.precio}}</span><span *ngIf="configuracion[0].pesos==1">(${{dolarArg*imp.precio | number:'1.0'}})</span> </p>
                        <!-- <p>
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-imp-' + imp.idImportaciones" aria-expanded="false" aria-controls="collapseExample">
                            <i class="fas fa-credit-card"></i> Cuotas
                        </button>
                        </p>
                        <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-imp-{{imp.idImportaciones}}">
                            <div class="card card-body mb-4 bnegro">
                                <div>
                                    <p class="text-center text-white">3 Cuotas de ${{((dolarArg*imp.precio)*25/100+dolarArg*imp.precio)/3 | number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de ${{((dolarArg*imp.precio)*55/100+dolarArg*imp.precio)/6 | number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*imp.precio)*84/100+dolarArg*imp.precio)/12 | number:'1.0'}}</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="text-center mt-4 mb-4">
                            <a href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20{{imp.nombre}}" target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12">
                    <div class="pt-2 cont-producto">
                        <h1 class="text-center crojo font-weight-lighter">¡MUY PRONTO!</h1>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- <a class="boton-promos shadow" href="#" routerLink="/promos" title="¡Ver promociones!"><i class="fas fa-percent fa-lg" style="margin-top: 5px;"></i></a> -->
<app-pie-pagina></app-pie-pagina>