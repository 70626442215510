import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-iphones-nuevos',
  templateUrl: './iphones-nuevos.component.html',
  styleUrls: ['./iphones-nuevos.component.css']
})
export class IphonesNuevosComponent implements OnInit {

  celularesNuevos;
  precio:number;
  modelo:number;
  memoria:number;
  oferta:boolean;
  resultado:boolean;

  constructor(private http:HttpClient) {
    this.http.get('https://likeiphone.com.ar/api/traerCelularesNuevos.php').subscribe((res:any) => {
      this.celularesNuevos=res;
      console.log(this.celularesNuevos);
    });
   }

   cambiarPrecio(){
     this.http.post('https://likeiphone.com.ar/api/modificarIphoneNuevo.php',
     {
      precio:this.precio,
      idCelular:this.modelo,
      memoria:this.memoria,
      oferta:this.oferta
     }).subscribe((result:any)=>{
      console.log(result);
      this.resultado=result.res;
      if(this.resultado==true){
        setTimeout(() => {
          this.resultado=false;
        }, 2000);
      }
     })
   }

   traerSesion() {
    this.http.get('https://likeiphone.com.ar/api/traerSesion.php').subscribe((acceso: any) => {
      if (acceso != 1) {
        window.location.href = './#/admin/acceso';
      }
    })
  }

  ngOnInit(): void {
    this.traerSesion();
  }

}
