import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mas-info',
  templateUrl: './mas-info.component.html',
  styleUrls: ['./mas-info.component.css']
})
export class MasInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
