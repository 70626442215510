import { Component, OnInit } from '@angular/core';
import { CotizariphoneService } from './cotizariphone.service';

@Component({
  selector: 'app-cotizariphone',
  templateUrl: './cotizariphone.component.html',
  styleUrls: ['./cotizariphone.component.css']
})
export class CotizariphoneComponent implements OnInit {

  modeloAcotizar: string = "";
  precioCotizacion=0;
  cotizacion;

  constructor(public cotizaciones:CotizariphoneService) {


    this.cotizaciones.getCotizacion('https://likeiphone.com.ar/api/traerCotizaciones.php').subscribe((res:any) => {
      this.cotizacion=res;
      console.log(this.cotizacion);
    });

  }

  cotizar(){
    console.log(this.modeloAcotizar);
    this.cotizaciones.getCotizacion('https://likeiphone.com.ar/api/traerCotizacion.php?modelo='+this.modeloAcotizar).subscribe((res:any) => {
      this.cotizacion=res;
      this.precioCotizacion=this.cotizacion[0].precio;
    });
    document.getElementById('iphone-seleccionado').innerText='IPHONE '+this.modeloAcotizar;
    document.getElementById('miiphone').style.display='none';
    document.getElementById('btn-cotizar').style.display='block';
    document.getElementById('iphone-seleccionado').style.display='block';
    console.log(window.location.host);
  }

  nuevaCotizacion(){
    location.reload();
  }

  ngOnInit(): void {
  }

}
