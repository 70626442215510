<app-menu></app-menu>
<div class="container-fluid" id="cont-usados">
    <div class="row">
        <div class="col-12">
            <h1 class="text-center text-black mt-5 mb-4" id="titulo-seccion">LIKE <span class="tipo-modelo">
                    PREMIUM</span></h1>
        </div>
        <div class="col-md-10 offset-md-1 mb-4">
            <div class="row">
                <div class="col">
                    <a routerLink="/outlet" class="btn-rojo"><i class="fas fa-store"></i> OUTLET</a>
                </div>
                <div class="col">
                    <a routerLink="/premium" class="btn-rojo"><i class="fas fa-bolt"></i> PREMIUM</a>
                </div>
            </div>
        </div>
        <div class="col-md-10 offset-md-1 mb-2">
            <h6 class="text-center text-black fw400">Nuestros Iphone usados son seleccionados por nuestros técnicos,
                garantizando un estado muy bueno del equipo.</h6>
            <h4 class="text-center text-white p-3 bgblack fw300 my-4"><i class="fas fa-mobile-alt"></i> Todos los
                equipos incluyen <span style="color:#ec0024;font-weight:bold;">cable tipo c</span>
            </h4>
            <h4 class="text-center text-white p-3 bgblack fw300 my-4"><i class="fas fa-shield-alt"></i> También contás
                con 60 días de garantía.</h4>
        </div>
    </div>

    <div class="row" *ngIf="usados.length>0">
        <div class="col-xl-10 offset-xl-1  animate__animated animate__fadeInUp">
            <div class="table-responsive mb-4">
                <div class="brojo p-2 text-white text-center">
                    <h3 class="fw300 m-0"><i class="fas fa-map-marker-alt"></i> Stock en La Plata (En el día)</h3>
                </div>
                <table class="table">
                    <thead>
                        <tr style="white-space: nowrap;text-align:center;">
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-tint"></i> COLOR</th>
                            <th><i class="fas fa-memory"></i> GB</th>
                            <th><i class="fas fa-battery-three-quarters"></i> BATERÍA</th>
                            <th><i class="fas fa-mobile"></i> PANTALLA</th>
                            <th><i class="fa fa-sticky-note" aria-hidden="true"></i> DESCRIPCIÓN</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <!-- <th><i class="fas fa-credit-card"></i> CUOTAS</th> -->
                            <!--<th><i class="fas fa-align-justify"></i> OBSERVACIONES</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usado of usados">
                            <td class="pt-3"> <span *ngIf="usado.oferta==1"><i class="fas fa-fire crojo fa-lg"></i><b>
                                        OFERTA </b></span> IPHONE {{usado.modelo}}</td>
                            <td class="pt-3">{{usado.color}}</td>
                            <td class="pt-3">{{usado.interna}}GB</td>
                            <td class="pt-3">{{usado.bateria}}</td>
                            <td class="pt-3">{{usado.pantalla}}</td>
                            <td class="pt-3" *ngIf="usado.descripcion">{{usado.descripcion}}</td>
                            <td class="pt-3" *ngIf="!usado.descripcion">-</td>
                            <td class="pt-3">
                                USD{{usado.precio}}<span *ngIf="configuracion[0].pesos==1">(${{usado.precio*dolarArg |
                                    number:'1.0'}})</span>
                                <br>
                                <p *ngIf="configuracion[0].cuotas==1" class="text-center mb-0 mt-2"><b>- 6 Cuotas</b> de
                                    ${{((dolarArg*usado.precio)*26/100+dolarArg*usado.precio)/6 | number:'1.2'}}</p>
                                <p *ngIf="configuracion[0].cuotas==1" class="text-center mb-0 mt-2"><b>- 12 Cuotas</b>
                                    de ${{((dolarArg*usado.precio)*26/100+dolarArg*usado.precio)/12 | number:'1.2'}}</p>
                            </td>
                            <!-- <td style="white-space: nowrap;">
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + usado.idUsado"  aria-expanded="false" aria-controls="collapseExample">
                                <i class="fas fa-credit-card"></i> Cuotas
                            </button>
                            <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{usado.idUsado}}">
                                <div class="card card-body mb-4 bnegro">
                                    <div *ngIf="usado.precio!=0">
                                        <p class="text-center"><span class="capacidad">Cuotas</span> </p>
                                        <p class="text-center text-white">3 Cuotas de ${{((dolarArg*usado.precio)*25/100+dolarArg*usado.precio)/3 | number:'1.0'}}</p>
                                        <p class="text-center text-white">6 Cuotas de ${{((dolarArg*usado.precio)*55/100+dolarArg*usado.precio)/6 | number:'1.0'}}</p>
                                        <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*usado.precio)*84/100+dolarArg*usado.precio)/12 | number:'1.0'}}</p>
                                    </div>
                                </div>
                            </div>
                        </td>-->
                            <!--<td></td>-->
                            <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20este%20equipo%20que%20se%20encuentra%20en%20La%20Plata:%20
                            %0A%0A----- 📱 iPhone%20{{usado.modelo}}%20-----
                            %0A%0A- 🖌 Color: {{usado.color}}
                            %0A%0A- 💾 Interna: {{usado.interna}}GB
                            %0A%0A- 🔋 Batería: {{usado.bateria}}
                            %0A%0A- 💰 Valor: USD{{usado.precio}}
                            " target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="usadosOlavarria.length>0">
        <div class="col-xl-10 offset-xl-1  animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <div class="brojo p-2 text-white text-center">
                    <h3 class="fw300 m-0"><i class="fas fa-map-marker-alt"></i> Stock en Olavarría</h3>
                </div>
                <table class="table">
                    <thead>
                        <tr style="white-space: nowrap;text-align:center;">
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-tint"></i> COLOR</th>
                            <th><i class="fas fa-memory"></i> GB</th>
                            <th><i class="fas fa-battery-three-quarters"></i> BATERÍA</th>
                            <th><i class="fas fa-mobile"></i> PANTALLA</th>
                            <th><i class="fa fa-sticky-note" aria-hidden="true"></i> DESCRIPCIÓN</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <!-- <th><i class="fas fa-credit-card"></i> CUOTAS</th> -->
                            <!--<th><i class="fas fa-align-justify"></i> OBSERVACIONES</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usado of usadosOlavarria">
                            <td class="pt-3"> <span *ngIf="usado.oferta==1"><i class="fas fa-fire crojo fa-lg"></i><b>
                                        OFERTA </b></span> IPHONE {{usado.modelo}}</td>
                            <td class="pt-3">{{usado.color}}</td>
                            <td class="pt-3">{{usado.interna}}GB</td>
                            <td class="pt-3">{{usado.bateria}}</td>
                            <td class="pt-3">{{usado.pantalla}}</td>
                            <td class="pt-3" *ngIf="usado.descripcion">{{usado.descripcion}}</td>
                            <td class="pt-3" *ngIf="!usado.descripcion">-</td>
                            <td class="pt-3">USD{{usado.precio}}<span
                                    *ngIf="configuracion[0].pesos==1">(${{usado.precio*dolarArg | number:'1.0'}})</span>
                            </td>
                            <!--<td style="white-space: nowrap;">
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + usado.idUsado"  aria-expanded="false" aria-controls="collapseExample">
                                <i class="fas fa-credit-card"></i> Cuotas
                            </button>
                            <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{usado.idUsado}}">
                                <div class="card card-body mb-4 bnegro">
                                    <div *ngIf="usado.precio!=0">
                                        <p class="text-center"><span class="capacidad">Cuotas</span> </p>
                                        <p class="text-center text-white">3 Cuotas de ${{((dolarArg*usado.precio)*25/100+dolarArg*usado.precio)/3 | number:'1.0'}}</p>
                                        <p class="text-center text-white">6 Cuotas de ${{((dolarArg*usado.precio)*55/100+dolarArg*usado.precio)/6 | number:'1.0'}}</p>
                                        <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*usado.precio)*84/100+dolarArg*usado.precio)/12 | number:'1.0'}}</p>
                                    </div>
                                </div>
                            </div>
                        </td>-->
                            <!--<td></td>-->
                            <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542281315285&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20este%20equipo%20que%20se%20encuentra%20en%20Olavarría:%20
                            %0A%0A----- 📱 iPhone%20{{usado.modelo}}%20-----
                            %0A%0A- 🖌 Color: {{usado.color}}
                            %0A%0A- 💾 Interna: {{usado.interna}}GB
                            %0A%0A- 🔋 Batería: {{usado.bateria}}
                            %0A%0A- 💰 Valor: USD{{usado.precio}}
                            " target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="usadosPalermo.length>0">
        <div class="col-xl-10 offset-xl-1  animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <div class="brojo p-2 text-white text-center">
                    <h3 class="fw300 m-0"><i class="fas fa-map-marker-alt"></i> Stock en Palermo</h3>
                </div>
                <table class="table">
                    <thead>
                        <tr style="white-space: nowrap;text-align:center;">
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-tint"></i> COLOR</th>
                            <th><i class="fas fa-memory"></i> GB</th>
                            <th><i class="fas fa-battery-three-quarters"></i> BATERÍA</th>
                            <th><i class="fas fa-mobile"></i> PANTALLA</th>
                            <th><i class="fa fa-sticky-note" aria-hidden="true"></i> DESCRIPCIÓN</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <!-- <th><i class="fas fa-credit-card"></i> CUOTAS</th> -->
                            <!--<th><i class="fas fa-align-justify"></i> OBSERVACIONES</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usado of usadosPalermo">
                            <td class="pt-3"> <span *ngIf="usado.oferta==1"><i class="fas fa-fire crojo fa-lg"></i><b>
                                        OFERTA </b></span> IPHONE {{usado.modelo}}</td>
                            <td class="pt-3">{{usado.color}}</td>
                            <td class="pt-3">{{usado.interna}}GB</td>
                            <td class="pt-3">{{usado.bateria}}</td>
                            <td class="pt-3">{{usado.pantalla}}</td>
                            <td class="pt-3" *ngIf="usado.descripcion">{{usado.descripcion}}</td>
                            <td class="pt-3" *ngIf="!usado.descripcion">-</td>
                            <td class="pt-3">USD{{usado.precio}}<span
                                    *ngIf="configuracion[0].pesos==1">(${{usado.precio*dolarArg | number:'1.0'}})</span>
                            </td>
                            <!--<td style="white-space: nowrap;">
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + usado.idUsado"  aria-expanded="false" aria-controls="collapseExample">
                                <i class="fas fa-credit-card"></i> Cuotas
                            </button>
                            <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{usado.idUsado}}">
                                <div class="card card-body mb-4 bnegro">
                                    <div *ngIf="usado.precio!=0">
                                        <p class="text-center"><span class="capacidad">Cuotas</span> </p>
                                        <p class="text-center text-white">3 Cuotas de ${{((dolarArg*usado.precio)*25/100+dolarArg*usado.precio)/3 | number:'1.0'}}</p>
                                        <p class="text-center text-white">6 Cuotas de ${{((dolarArg*usado.precio)*55/100+dolarArg*usado.precio)/6 | number:'1.0'}}</p>
                                        <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*usado.precio)*84/100+dolarArg*usado.precio)/12 | number:'1.0'}}</p>
                                    </div>
                                </div>
                            </div>
                        </td>-->
                            <!--<td></td>-->
                            <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20este%20equipo%20que%20se%20encuentra%20en%20Palermo:%20
                            %0A%0A----- 📱 iPhone%20{{usado.modelo}}%20-----
                            %0A%0A- 🖌 Color: {{usado.color}}
                            %0A%0A- 💾 Interna: {{usado.interna}}GB
                            %0A%0A- 🔋 Batería: {{usado.bateria}}
                            %0A%0A- 💰 Valor: USD{{usado.precio}}
                            " target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="usadosPehuajo.length>0">
        <div class="col-xl-10 offset-xl-1  animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <div class="brojo p-2 text-white text-center">
                    <h3 class="fw300 m-0"><i class="fas fa-map-marker-alt"></i> Stock en Pehuajó</h3>
                </div>
                <table class="table">
                    <thead>
                        <tr style="white-space: nowrap;text-align:center;">
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-tint"></i> COLOR</th>
                            <th><i class="fas fa-memory"></i> GB</th>
                            <th><i class="fas fa-battery-three-quarters"></i> BATERÍA</th>
                            <th><i class="fas fa-mobile"></i> PANTALLA</th>
                            <th><i class="fa fa-sticky-note" aria-hidden="true"></i> DESCRIPCIÓN</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <!-- <th><i class="fas fa-credit-card"></i> CUOTAS</th> -->
                            <!--<th><i class="fas fa-align-justify"></i> OBSERVACIONES</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usado of usadosPehuajo">
                            <td class="pt-3"> <span *ngIf="usado.oferta==1"><i class="fas fa-fire crojo fa-lg"></i><b>
                                        OFERTA </b></span> IPHONE {{usado.modelo}}</td>
                            <td class="pt-3">{{usado.color}}</td>
                            <td class="pt-3">{{usado.interna}}GB</td>
                            <td class="pt-3">{{usado.bateria}}</td>
                            <td class="pt-3">{{usado.pantalla}}</td>
                            <td class="pt-3" *ngIf="usado.descripcion">{{usado.descripcion}}</td>
                            <td class="pt-3" *ngIf="!usado.descripcion">-</td>
                            <td class="pt-3">USD{{usado.precio}}<span
                                    *ngIf="configuracion[0].pesos==1">(${{usado.precio*dolarArg | number:'1.0'}})</span>
                            </td>
                            <!--<td style="white-space: nowrap;">
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + usado.idUsado"  aria-expanded="false" aria-controls="collapseExample">
                                <i class="fas fa-credit-card"></i> Cuotas
                            </button>
                            <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{usado.idUsado}}">
                                <div class="card card-body mb-4 bnegro">
                                    <div *ngIf="usado.precio!=0">
                                        <p class="text-center"><span class="capacidad">Cuotas</span> </p>
                                        <p class="text-center text-white">3 Cuotas de ${{((dolarArg*usado.precio)*25/100+dolarArg*usado.precio)/3 | number:'1.0'}}</p>
                                        <p class="text-center text-white">6 Cuotas de ${{((dolarArg*usado.precio)*55/100+dolarArg*usado.precio)/6 | number:'1.0'}}</p>
                                        <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*usado.precio)*84/100+dolarArg*usado.precio)/12 | number:'1.0'}}</p>
                                    </div>
                                </div>
                            </div>
                        </td>-->
                            <!--<td></td>-->
                            <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542396411299&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20este%20equipo%20que%20se%20encuentra%20en%20Pehuajó:%20
                            %0A%0A----- 📱 iPhone%20{{usado.modelo}}%20-----
                            %0A%0A- 🖌 Color: {{usado.color}}
                            %0A%0A- 💾 Interna: {{usado.interna}}GB
                            %0A%0A- 🔋 Batería: {{usado.bateria}}
                            %0A%0A- 💰 Valor: USD{{usado.precio}}
                            " target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="usadosAyacucho.length>0">
        <div class="col-xl-10 offset-xl-1  animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <div class="brojo p-2 text-white text-center">
                    <h3 class="fw300 m-0"><i class="fas fa-map-marker-alt"></i> Stock en Ayacucho</h3>
                </div>
                <table class="table">
                    <thead>
                        <tr style="white-space: nowrap;text-align:center;">
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-tint"></i> COLOR</th>
                            <th><i class="fas fa-memory"></i> GB</th>
                            <th><i class="fas fa-battery-three-quarters"></i> BATERÍA</th>
                            <th><i class="fas fa-mobile"></i> PANTALLA</th>
                            <th><i class="fa fa-sticky-note" aria-hidden="true"></i> DESCRIPCIÓN</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <!-- <th><i class="fas fa-credit-card"></i> CUOTAS</th> -->
                            <!--<th><i class="fas fa-align-justify"></i> OBSERVACIONES</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usado of usadosAyacucho">
                            <td class="pt-3"> <span *ngIf="usado.oferta==1"><i class="fas fa-fire crojo fa-lg"></i><b>
                                        OFERTA </b></span> IPHONE {{usado.modelo}}</td>
                            <td class="pt-3">{{usado.color}}</td>
                            <td class="pt-3">{{usado.interna}}GB</td>
                            <td class="pt-3">{{usado.bateria}}</td>
                            <td class="pt-3">{{usado.pantalla}}</td>
                            <td class="pt-3" *ngIf="usado.descripcion">{{usado.descripcion}}</td>
                            <td class="pt-3" *ngIf="!usado.descripcion">-</td>
                            <td class="pt-3">USD{{usado.precio}}<span
                                    *ngIf="configuracion[0].pesos==1">(${{usado.precio*dolarArg | number:'1.0'}})</span>
                            </td>
                            <!--<td style="white-space: nowrap;">
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + usado.idUsado"  aria-expanded="false" aria-controls="collapseExample">
                                <i class="fas fa-credit-card"></i> Cuotas
                            </button>
                            <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{usado.idUsado}}">
                                <div class="card card-body mb-4 bnegro">
                                    <div *ngIf="usado.precio!=0">
                                        <p class="text-center"><span class="capacidad">Cuotas</span> </p>
                                        <p class="text-center text-white">3 Cuotas de ${{((dolarArg*usado.precio)*25/100+dolarArg*usado.precio)/3 | number:'1.0'}}</p>
                                        <p class="text-center text-white">6 Cuotas de ${{((dolarArg*usado.precio)*55/100+dolarArg*usado.precio)/6 | number:'1.0'}}</p>
                                        <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*usado.precio)*84/100+dolarArg*usado.precio)/12 | number:'1.0'}}</p>
                                    </div>
                                </div>
                            </div>
                        </td>-->
                            <!--<td></td>-->
                            <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20este%20equipo%20que%20se%20encuentra%20en%20Ayacucho:%20
                            %0A%0A----- 📱 iPhone%20{{usado.modelo}}%20-----
                            %0A%0A- 🖌 Color: {{usado.color}}
                            %0A%0A- 💾 Interna: {{usado.interna}}GB
                            %0A%0A- 🔋 Batería: {{usado.bateria}}
                            %0A%0A- 💰 Valor: USD{{usado.precio}}
                            " target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="usadosPinamar.length>0">
        <div class="col-xl-10 offset-xl-1  animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <div class="brojo p-2 text-white text-center">
                    <h3 class="fw300 m-0"><i class="fas fa-map-marker-alt"></i> Stock en Pinamar</h3>
                </div>
                <table class="table">
                    <thead>
                        <tr style="white-space: nowrap;text-align:center;">
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-tint"></i> COLOR</th>
                            <th><i class="fas fa-memory"></i> GB</th>
                            <th><i class="fas fa-battery-three-quarters"></i> BATERÍA</th>
                            <th><i class="fas fa-mobile"></i> PANTALLA</th>
                            <th><i class="fa fa-sticky-note" aria-hidden="true"></i> DESCRIPCIÓN</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <!-- <th><i class="fas fa-credit-card"></i> CUOTAS</th> -->
                            <!--<th><i class="fas fa-align-justify"></i> OBSERVACIONES</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usado of usadosPinamar">
                            <td class="pt-3"> <span *ngIf="usado.oferta==1"><i class="fas fa-fire crojo fa-lg"></i><b>
                                        OFERTA </b></span> IPHONE {{usado.modelo}}</td>
                            <td class="pt-3">{{usado.color}}</td>
                            <td class="pt-3">{{usado.interna}}GB</td>
                            <td class="pt-3">{{usado.bateria}}</td>
                            <td class="pt-3">{{usado.pantalla}}</td>
                            <td class="pt-3" *ngIf="usado.descripcion">{{usado.descripcion}}</td>
                            <td class="pt-3" *ngIf="!usado.descripcion">-</td>
                            <td class="pt-3">USD{{usado.precio}}<span
                                    *ngIf="configuracion[0].pesos==1">(${{usado.precio*dolarArg | number:'1.0'}})</span>
                            </td>
                            <!--<td style="white-space: nowrap;">
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + usado.idUsado"  aria-expanded="false" aria-controls="collapseExample">
                                <i class="fas fa-credit-card"></i> Cuotas
                            </button>
                            <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{usado.idUsado}}">
                                <div class="card card-body mb-4 bnegro">
                                    <div *ngIf="usado.precio!=0">
                                        <p class="text-center"><span class="capacidad">Cuotas</span> </p>
                                        <p class="text-center text-white">3 Cuotas de ${{((dolarArg*usado.precio)*25/100+dolarArg*usado.precio)/3 | number:'1.0'}}</p>
                                        <p class="text-center text-white">6 Cuotas de ${{((dolarArg*usado.precio)*55/100+dolarArg*usado.precio)/6 | number:'1.0'}}</p>
                                        <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*usado.precio)*84/100+dolarArg*usado.precio)/12 | number:'1.0'}}</p>
                                    </div>
                                </div>
                            </div>
                        </td>-->
                            <!--<td></td>-->
                            <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20este%20equipo%20que%20se%20encuentra%20en%20Pinamar:%20
                            %0A%0A----- 📱 iPhone%20{{usado.modelo}}%20-----
                            %0A%0A- 🖌 Color: {{usado.color}}
                            %0A%0A- 💾 Interna: {{usado.interna}}GB
                            %0A%0A- 🔋 Batería: {{usado.bateria}}
                            %0A%0A- 💰 Valor: USD{{usado.precio}}
                            " target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="usadosBolivar.length>0">
        <div class="col-xl-10 offset-xl-1  animate__animated animate__fadeInUp">
            <div class="table-responsive mb-5">
                <div class="brojo p-2 text-white text-center">
                    <h3 class="fw300 m-0"><i class="fas fa-map-marker-alt"></i> Stock en Bolivar</h3>
                </div>
                <table class="table">
                    <thead>
                        <tr style="white-space: nowrap;text-align:center;">
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-tint"></i> COLOR</th>
                            <th><i class="fas fa-memory"></i> GB</th>
                            <th><i class="fas fa-battery-three-quarters"></i> BATERÍA</th>
                            <th><i class="fas fa-mobile"></i> PANTALLA</th>
                            <th><i class="fa fa-sticky-note" aria-hidden="true"></i> DESCRIPCIÓN</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <!-- <th><i class="fas fa-credit-card"></i> CUOTAS</th> -->
                            <!--<th><i class="fas fa-align-justify"></i> OBSERVACIONES</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usado of usadosBolivar">
                            <td class="pt-3"> <span *ngIf="usado.oferta==1"><i class="fas fa-fire crojo fa-lg"></i><b>
                                        OFERTA </b></span> IPHONE {{usado.modelo}}</td>
                            <td class="pt-3">{{usado.color}}</td>
                            <td class="pt-3">{{usado.interna}}GB</td>
                            <td class="pt-3">{{usado.bateria}}</td>
                            <td class="pt-3">{{usado.pantalla}}</td>
                            <td class="pt-3" *ngIf="usado.descripcion">{{usado.descripcion}}</td>
                            <td class="pt-3" *ngIf="!usado.descripcion">-</td>
                            <td class="pt-3">USD{{usado.precio}}<span
                                    *ngIf="configuracion[0].pesos==1">(${{usado.precio*dolarArg | number:'1.0'}})</span>
                            </td>
                            <!--<td style="white-space: nowrap;">
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + usado.idUsado"  aria-expanded="false" aria-controls="collapseExample">
                                <i class="fas fa-credit-card"></i> Cuotas
                            </button>
                            <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{usado.idUsado}}">
                                <div class="card card-body mb-4 bnegro">
                                    <div *ngIf="usado.precio!=0">
                                        <p class="text-center"><span class="capacidad">Cuotas</span> </p>
                                        <p class="text-center text-white">3 Cuotas de ${{((dolarArg*usado.precio)*25/100+dolarArg*usado.precio)/3 | number:'1.0'}}</p>
                                        <p class="text-center text-white">6 Cuotas de ${{((dolarArg*usado.precio)*55/100+dolarArg*usado.precio)/6 | number:'1.0'}}</p>
                                        <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*usado.precio)*84/100+dolarArg*usado.precio)/12 | number:'1.0'}}</p>
                                    </div>
                                </div>
                            </div>
                        </td>-->
                            <!--<td></td>-->
                            <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542314403247&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20este%20equipo%20que%20se%20encuentra%20en%20Bolivar:%20
                            %0A%0A----- 📱 iPhone%20{{usado.modelo}}%20-----
                            %0A%0A- 🖌 Color: {{usado.color}}
                            %0A%0A- 💾 Interna: {{usado.interna}}GB
                            %0A%0A- 🔋 Batería: {{usado.bateria}}
                            %0A%0A- 💰 Valor: USD{{usado.precio}}
                            " target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="openbox.length>0">
        <div class="col-12">
            <h1 class="text-center text-black mt-2 mb-4" id="titulo-seccion">IPHONE <span class="tipo-modelo"> OPEN
                    BOX</span></h1>
        </div>
        <div class="col-md-10 offset-md-1 mb-2">
            <h6 class="text-center text-black fw400">
                Un equipo «Open Box» consiste en productos de marcas originales, cuya caja o empaque original ha sido
                abierto. Algunos de estos equipos pueden haber sido utilizados para colocarse en mostrador en tiendas.
            </h6>
        </div>
    </div>

    <div class="row" *ngIf="openbox.length>0">
        <div class="col-xl-10 offset-xl-1  animate__animated animate__fadeInUp">
            <div class="table-responsive mb-4">
                <div class="brojo p-2 text-white text-center">
                    <h3 class="fw300 m-0"><i class="fas fa-map-marker-alt"></i> Stock en La Plata (En el día)</h3>
                </div>
                <table class="table">
                    <thead>
                        <tr style="white-space: nowrap;text-align:center;">
                            <th><i class="fas fa-mobile-alt"></i> MODELO</th>
                            <th><i class="fas fa-tint"></i> COLOR</th>
                            <th><i class="fas fa-memory"></i> GB</th>
                            <th><i class="fas fa-battery-three-quarters"></i> BATERÍA</th>
                            <th><i class="fas fa-mobile"></i> PANTALLA</th>
                            <th><i class="fa fa-sticky-note" aria-hidden="true"></i> DESCRIPCIÓN</th>
                            <th><i class="fas fa-dollar-sign"></i> VALOR</th>
                            <!-- <th><i class="fas fa-credit-card"></i> CUOTAS</th> -->
                            <!--<th><i class="fas fa-align-justify"></i> OBSERVACIONES</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let opbx of openbox">
                            <td class="pt-3"> <span *ngIf="opbx.oferta==1"><i class="fas fa-fire crojo fa-lg"></i><b>
                                        OFERTA </b></span> IPHONE {{opbx.modelo}}</td>
                            <td class="pt-3">{{opbx.color}}</td>
                            <td class="pt-3">{{opbx.interna}}GB</td>
                            <td class="pt-3">{{opbx.bateria}}</td>
                            <td class="pt-3">
                                USD{{opbx.precio}}<span *ngIf="configuracion[0].pesos==1">(${{opbx.precio*dolarArg |
                                    number:'1.0'}})</span>
                                <br>
                                <p *ngIf="configuracion[0].cuotas==1" class="text-center mb-0 mt-2"><b>- 6 Cuotas</b> de
                                    ${{((dolarArg*opbx.precio)*26/100+dolarArg*opbx.precio)/6 | number:'1.2'}}</p>
                                <p *ngIf="configuracion[0].cuotas==1" class="text-center mb-0 mt-2"><b>- 12 Cuotas</b>
                                    de ${{((dolarArg*opbx.precio)*26/100+dolarArg*opbx.precio)/12 | number:'1.2'}}</p>
                            </td>
                            <!-- <td style="white-space: nowrap;">
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + usado.idUsado"  aria-expanded="false" aria-controls="collapseExample">
                                <i class="fas fa-credit-card"></i> Cuotas
                            </button>
                            <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{usado.idUsado}}">
                                <div class="card card-body mb-4 bnegro">
                                    <div *ngIf="usado.precio!=0">
                                        <p class="text-center"><span class="capacidad">Cuotas</span> </p>
                                <p class="text-center text-white">3 Cuotas de ${{((dolarArg*usado.precio)*25/100+dolarArg*usado.precio)/3 | number:'1.0'}}</p>
                                <p class="text-center text-white">6 Cuotas de ${{((dolarArg*usado.precio)*55/100+dolarArg*usado.precio)/6 | number:'1.0'}}</p>
                                <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*usado.precio)*84/100+dolarArg*usado.precio)/12 | number:'1.0'}}</p>
            </div>
        </div>
    </div>
    </td>-->
                            <!--<td></td>-->
                            <td style="white-space: nowrap;"><a ngClass="d-block btn whatsapp" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20este%20equipo%20Open%20Box:%20
                            %0A%0A----- 📱 iPhone%20{{opbx.modelo}}%20-----
                            %0A%0A- 🖌 Color: {{opbx.color}}
                            %0A%0A- 💾 Interna: {{opbx.interna}}GB
                            %0A%0A- 🔋 Batería: {{opbx.bateria}}
                            %0A%0A- 💰 Valor: USD{{opbx.precio}}
                            " target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-10 offset-md-1">
            <h1 class="text-center text-white p-3 bgblack mt-2 mb-4" id="titulo-seccion"><i class="fas fa-laptop"></i> MACBOOK AIR USADAS</h1>
            <div class="p-3">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-12  text-white text-center p-4">
                        <div class="brojo px-2 py-4">
                            <h4 class="fw300">MACBOOK AIR 13"/I5 1.6GHZ/8GB-128GB/AÑO 2015</h4>
                            <h4 class="fw300">MBA-13-16-E15-8128-B</h4>
                            <h4 class="fw300">$420 USD 💰</h4>
                            <a ngClass="d-block m-auto btn whatsapp w-50" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20las%20Macbooks%20usadas." target="_blank" class="whatsapp">
                                <i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-12  text-white text-center p-4">
                        <div class="bgblack px-2 py-4">
                            <h4 class="fw300">MACBOOK AIR 11"/I5 1.6GHZ/8GB-128GB/AÑO 2015</h4>
                            <h4 class="fw300">MBA-11-16-E15-8128-B</h4>
                            <h4 class="fw300">$480 USD 💰</h4>
                            <a ngClass="d-block m-auto btn whatsapp w-50" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20las%20Macbooks%20usadas." target="_blank" class="whatsapp">
                                <i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-12 text-white text-center p-4">
                        <div class="brojo px-2 py-4">
                            <h4 class="fw300">MACBOOK AIR 13"/I5 1.6GHZ/8GB-256GB/AÑO 2015</h4>
                            <h4 class="fw300">MBA-13-16-E15-8256</h4>
                            <h4 class="fw300">$500 USD 💰</h4>
                            <a ngClass="d-block m-auto btn whatsapp w-50" href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20las%20Macbooks%20usadas." target="_blank" class="whatsapp">
                                <i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <h4 class="text-center font-weight-light"><i class="fas fa-plane-departure crojo"></i> ¡Realizamos envíos a todo el
        país!</h4>


</div>
<a class="boton-promos shadow" href="#" routerLink="/promos" title="¡Ver promociones!"><i class="fas fa-percent fa-lg"
        style="margin-top: 5px;"></i></a>
<app-pie-pagina></app-pie-pagina>