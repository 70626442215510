<app-menu></app-menu>
<div class="container-fluid px-0" id="cont-nuevos">
    <div class="row m-0">
        <div class="col-12 text-white">
            <h1 class="text-center text-black mt-5 mb-4" id="titulo-seccion">IPADS Y MACBOOKS</h1>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-xl-10 offset-xl-1">
            <div class="row">
                <div class="col-md-4 animate__animated animate__fadeInUp" *ngFor="let ipad of ipads">
                    <div class="pt-2 cont-iphone">
                        <img *ngIf="ipad.tipo == 'ipad'" class="d-block mx-auto" src="../../assets/Ipads/{{ipad.foto}}" height="200" alt="">
                        <img *ngIf="ipad.tipo == 'macbook'" class="d-block mx-auto" src="../../assets/macbook/{{ipad.foto}}" height="200" alt="">
                        <h4 class="text-center modelo mt-3 mb-4"><span class="tipo-modelo">{{ipad.nombre}}</span></h4>
                        <p class="text-center" *ngIf="ipad.interna64!=0"><span class="capacidad"><i class="fas fa-memory"></i> 64GB</span> ---> <span class="dolares-precio">USD{{ipad.interna64}}</span><span *ngIf="configuracion[0].pesos==1">    (${{dolarArg*ipad.interna64 | number:'1.0'}})</span> </p>
                        <p class="text-center" *ngIf="ipad.interna128!=0"><span class="capacidad"><i class="fas fa-memory"></i> 128GB</span> ---> <span class="dolares-precio">USD{{ipad.interna128}}</span><span *ngIf="configuracion[0].pesos==1"> (${{dolarArg*ipad.interna128 | number:'1.0'}})</span> </p>
                        <p class="text-center" *ngIf="ipad.interna256!=0"><span class="capacidad"><i class="fas fa-memory"></i> 256GB</span> ---> <span class="dolares-precio">USD{{ipad.interna256}}</span><span *ngIf="configuracion[0].pesos==1"> (${{dolarArg*ipad.interna256 | number:'1.0'}})</span> </p>
                        <p class="text-center" *ngIf="ipad.interna512!=0"><span class="capacidad"><i class="fas fa-memory"></i> 512GB</span> ---> <span class="dolares-precio">USD{{ipad.interna512}}</span><span *ngIf="configuracion[0].pesos==1"> (${{dolarArg*ipad.interna512 | number:'1.0'}})</span> </p>
                        <p class="text-center" *ngIf="ipad.interna1024!=0"><span class="capacidad"><i class="fas fa-memory"></i> 1TB</span> ---> <span class="dolares-precio">USD{{ipad.interna1024}}</span><span *ngIf="configuracion[0].pesos==1"> (${{dolarArg*ipad.interna1024 | number:'1.0'}})</span> </p>
                        <!-- <p>
                            <button class="btn d-block mx-auto btn-cuotas" type="button" data-toggle="collapse" [attr.data-target]="'#cuotas-cel-' + celular.idCelular" aria-expanded="false" aria-controls="collapseExample">
                            <i class="fas fa-credit-card"></i> Cuotas
                        </button>
                        </p>
                        <div class="collapse pl-4 pr-4 pt-2 pb-2" id="cuotas-cel-{{ipad.idIpad}}">
                            <div class="card card-body mb-4 bnegro">
                                <div *ngIf="ipad.interna64!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i> 64GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de ${{((dolarArg*ipad.interna64)*25/100+dolarArg*ipad.interna64)/3 | number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de ${{((dolarArg*ipad.interna64)*55/100+dolarArg*ipad.interna64)/6 | number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*ipad.interna64)*84/100+dolarArg*ipad.interna64)/12 | number:'1.0'}}</p>
                                </div>
                                <hr class="bgris" *ngIf="ipad.interna128!=0">
                                <div *ngIf="ipad.interna128!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i> 128GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de ${{((dolarArg*ipad.interna128)*25/100+dolarArg*ipad.interna128)/3 | number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de ${{((dolarArg*ipad.interna128)*55/100+dolarArg*ipad.interna128)/6 | number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*ipad.interna128)*84/100+dolarArg*ipad.interna128)/12 | number:'1.0'}}</p>
                                </div>
                                <hr class="bgris" *ngIf="ipad.interna256!=0">
                                <div *ngIf="ipad.interna256!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i> 256GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de ${{((dolarArg*ipad.interna256)*25/100+dolarArg*ipad.interna256)/3 | number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de ${{((dolarArg*ipad.interna256)*55/100+dolarArg*ipad.interna256)/6 | number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*ipad.interna256)*84/100+dolarArg*ipad.interna256)/12 | number:'1.0'}}</p>
                                </div>
                                <hr class="bgris" *ngIf="ipad.interna512!=0">
                                <div *ngIf="ipad.interna512!=0">
                                    <p class="text-center"><span class="capacidad"><i class="fas fa-memory"></i> 512GB</span> </p>
                                    <p class="text-center text-white">3 Cuotas de ${{((dolarArg*ipad.interna512)*25/100+dolarArg*ipad.interna512)/3 | number:'1.0'}}</p>
                                    <p class="text-center text-white">6 Cuotas de ${{((dolarArg*ipad.interna512)*55/100+dolarArg*ipad.interna512)/6 | number:'1.0'}}</p>
                                    <p class="text-center text-white mb-0">12 Cuotas de ${{((dolarArg*ipad.interna512)*84/100+dolarArg*ipad.interna512)/12 | number:'1.0'}}</p>
                                </div>
                            </div>
                        </div> -->
                        <p class="text-center stock" *ngIf="ipad.estado==1">Disponible</p>
                        <p class="text-center stock" *ngIf="ipad.estado==0">No disponible</p>
                        <div class="text-center mt-4 mb-4">
                            <a href="https://api.whatsapp.com/send?phone=542215900681&text=Hola%20LikeIphone%2C%20quiero%20hacerles%20una%20consulta%20sobre%20el%20iPhone%20{{ipad.nombre}}%20" target="_blank" class="whatsapp"><i class="fab fa-whatsapp fa-lg"></i> ¡Consultar!</a>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 bg-white">
                    <div class="pt-2 cont-producto">
                        <h1 class="text-center crojo font-weight-lighter">¡MUY PRONTO!</h1>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- <a class="boton-promos shadow" href="#" routerLink="/promos" title="¡Ver promociones!"><i class="fas fa-percent fa-lg" style="margin-top: 5px;"></i></a> -->
    <app-pie-pagina></app-pie-pagina>