<app-menu-admin></app-menu-admin>
<div class="container-fluid">
    <div class="row pt-2" *ngIf="tablaReparaciones==true">
        <div class="col-md-8 offset-md-2 col-sm-12 col-xs-12">
            <h4 class="text-center text-white mb-5 mt-5 fw-100">REPARACIONES</h4>
            <table id="table" class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Modelo</th>
                        <th>Tipo</th>
                        <th>Precio</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let reparacion of reparaciones">
                        <td>
                            <button (click)="buscar(reparacion.idReparacion)" class="btn btn-tabla-mobile mt-2" id="btn-modificar-{{reparacion.idreparacion}}"><i class="fas fa-edit"></i></button>
                            <!-- <button (click)="eliminar(reparacion.idreparacion)" class="btn btn-tabla-mobile mt-2" id="btn-eliminar-{{reparacion.idreparacion}}"><i class="fas fa-trash"></i></button> -->
                            <img class="mt-2 loading" id="loading-{{reparacion.idReparacion}}" style="display:none;" src="../../assets/img/loading.gif" height="30" alt="">
                        </td>
                        <td>{{reparacion.modelo}}</td>
                        <td>{{reparacion.tipoArreglo.slice(0,1).toUpperCase() + reparacion.tipoArreglo.slice(1)}}</td>
                        <td>${{reparacion.precio}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row" id="form-modificar" *ngIf="formModificar==true">
        <div class="col-12">
            <h4 class="text-center text-white mb-5 fw-100 mt-5">MODIFICAR</h4>
        </div>
        <form class="col-md-4 offset-md-4" action="">
            <div class="form-group">
                <label class="text-white bgprincipal p-2"><i class="fas fa-mobile"></i> Modelo: IPHONE {{reparacionSeleccionado.modelo}}</label>
            </div>
            <div class="form-group">
                <label class="text-white bgprincipal p-2">Tipo: {{reparacionSeleccionado.tipoArreglo.slice(0,1).toUpperCase() + reparacionSeleccionado.tipoArreglo.slice(1)}}</label>
            </div>
            <div class="form-group">
                <label for="precio" class="mt-2 mb-1 text-white"><i class="fas fa-dollar-sign"></i> Precio</label>
                <input type="text" class="form-control" [(ngModel)]="reparacionSeleccionado.precio" [value]="reparacionSeleccionado.precio" name="precio" id="precio" aria-describedby="helpId" placeholder="Precio">
            </div>
            <div class="text-center mb-4">
                <button class=" btn mt-4" (click)="volver()" style="margin-right:10px;width: 140px;" type="button"><i class="fas fa-chevron-left"></i> Volver</button>
                <button class=" btn mt-4" (click)="modificar()" style=" margin-left:10px;width: 140px; " type="submit ">Modificar <i class="fas fa-check "></i></button>
            </div>

        </form>
    </div>

    <!-- <div class="row vh-100 " *ngIf="resultado==true ">
        <div class="d-flex justify-content-center align-items-center w-100 ">
            <div class="col-md-8 ">
                <h5 class="text-center text-white mb-5 fw-100 ">
                    <img src="../../assets/img/done.png " class="d-block mx-auto mb-2 " height="50 " alt=" "> ¡ACTUALIZADO!
                </h5>
            </div>
        </div>
    </div>  -->

</div>