<!--<video autoplay muted loop id="myVideo">
    <source src="../assets/video/fondo-trailer.mp4" type="video/mp4">
</video>-->

<div class="container-fluid" id="cont-general">
    <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-md-6" id="cont-menu">
            <img class="d-block mx-auto" height="90" src="../assets/img/logoTrans.png" alt="">
            <div class="mt-4 text-center" id="cont-menu">
                <a routerLink="/nuevos-iphone" class="menu-list d-block mx-auto mt-2 p-2" href="">IPHONE NUEVOS</a>
                <a routerLink="/premium" class="menu-list d-block mx-auto mt-2 p-2" href="">IPHONE USADOS</a>
                <!-- <a routerLink="/promos" class="menu-list d-block mx-auto mt-2 p-2" href=""> <i
                        class="fas fa-percent"></i> PROMOS</a> -->
                <a routerLink="/ipads-y-macbooks" class="menu-list d-block mx-auto mt-2 p-2" href="">IPADS Y
                    MACBOOKS</a>
                <a routerLink="/formas-de-pago" class="menu-list d-block mx-auto mt-2 p-2" href="">FORMAS DE PAGO</a>
                <a routerLink="/reparaciones" class="menu-list d-block mx-auto mt-2 p-2" href="">REPARACIONES</a>
                <a routerLink="/cotizar-mi-iphone" class="menu-list d-block mx-auto mt-2 p-2" href="">COTIZAR MI
                    IPHONE</a>
                <a routerLink="/importaciones" class="menu-list d-block mx-auto mt-2 p-2" href=""><i
                        class="fas fa-plane-departure"></i> IMPORTACIONES</a>
                <a routerLink="/mas-info" class="menu-list d-block mx-auto mt-2 p-2" href="">MÁS INFO</a>
            </div>
            <div class="text-center mt-4" id="cont-redes">
                <a target="_blank"
                    href="https://www.google.com.ar/maps/place/Plaza+Olaz%C3%A1bal+137,+B1900+La+Plata,+Provincia+de+Buenos+Aires/@-34.9062225,-57.9616776,21z/data=!4m5!3m4!1s0x95a2e7b335476315:0xac41e8d9657ad0bd!8m2!3d-34.9062148!4d-57.9615777"
                    class="red ml-2 mr-2"><i class="fas fa-map-marker-alt"></i></a>
                <a href="https://www.instagram.com/likeiphone.arg/" target="_blank" class="red ml-2 mr-2"><i
                        class="fab fa-instagram"></i></a>
                <a target="_blank" href="https://www.facebook.com/Like.iphone.arg" class="red ml-2 mr-2"><i
                        class="fab fa-facebook-f"></i></a>
                <a target="_blank" href="https://www.tiktok.com/@likeiphone.ok" class="red ml-2 mr-2"><i
                        class="fab fa-tiktok"></i></a>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<!-- <div class="modal fade" id="modalNavidad" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="background-color: black;">
            <div class="modal-body p-4">
                <img class="d-block mx-auto" height="50" src="../assets/img/logoTrans.png" alt="">
                <h4 class="text-center text-white mt-5">¡Felices Fiestas!</h4>
                <p class="text-center p-3 text-white mt-2">🎄🌟 Queridos clientes, en esta época especial,
                    queremos
                    agradecerles
                    por su confianza y
                    apoyo. Que estas fiestas estén llenas de paz, amor y momentos inolvidables junto a sus seres
                    queridos. ¡Feliz Navidad y próspero Año Nuevo! 🎅🎁</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div> -->