<app-menu-admin></app-menu-admin>
<div class="container-fluid">
    <div class="row vh-100">
        <div class="d-flex justify-content-center align-items-center w-100">
            <div class="row w-100">
                <div class="col-md-4 offset-md-4 col-xs-12" *ngIf="resultado!=true">
                    <h4 class="text-center text-white mb-5 fw-100">IPHONES NUEVOS</h4>
                    <form #myForm="ngForm" (ngSubmit)="cambiarPrecio();myForm.reset();">
                        <label class="text-white  mt-2 mb-1" for="modelo">Seleccionar Iphone</label>
                        <select class="form-control" [(ngModel)]="modelo" name="modelo" id="modelo">
                        <option  value="{{cotizacion.idCelular}}" *ngFor="let cotizacion of celularesNuevos">IPHONE {{cotizacion.modelo}}</option>
                    </select>
                        <label class="text-white mt-2 mb-1" for="modelo">Seleccionar Modelo</label>
                        <select class="form-control mb-4" [(ngModel)]="memoria" name="memoria" id="memoria">
                        <option value="64">64GB</option>
                        <option value="128">128GB</option>
                        <option value="256">256GB</option>
                        <option value="512">512GB</option>
                        <option value="1024">1024GB</option>
                    </select>

                        <input [(ngModel)]="precio" placeholder="Precio en USD" name="precio" id="precio" class="form-control" type="number">
                        <div class="form-check">
                            <label class="form-check-label text-white mt-4">
                            <input type="checkbox" style="height: 16px;" class="form-check-input" name="oferta" id="oferta" [(ngModel)]="oferta">
                                ¿Está en oferta?
                            </label>
                        </div>
                        <div class="text-center">
                            <button class=" btn mt-4" style="margin-left:10px;width: 100px;" type="submit">Modificar</button>
                        </div>
                    </form>
                </div>
                <div class="col-md-8 offset-md-2" *ngIf="resultado==true">
                    <h5 class="text-center text-white mb-5 fw-100">
                        <img src="../../assets/img/done.png" class="d-block mx-auto mb-2" height="50" alt=""> ¡ACTUALIZADO!
                    </h5>
                </div>
            </div>
        </div>
    </div>
</div>