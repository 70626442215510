import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adm-reparaciones',
  templateUrl: './adm-reparaciones.component.html',
  styleUrls: ['./adm-reparaciones.component.css']
})
export class AdmReparacionesComponent implements OnInit {

  public reparaciones = [];
  public formModificar = false;
  public tablaReparaciones = true;
  public reparacionSeleccionado;
  public resultado = false;

  constructor(private http: HttpClient) {
    this.http.get('https://likeiphone.com.ar/api/traerReparaciones.php').subscribe((res: any) => {
      this.reparaciones = res;
    });

  }

  buscar(idReparacion) {
    this.formModificar = true;
    this.tablaReparaciones = false;
    this.reparacionSeleccionado = this.reparaciones.find(obj => obj.idReparacion === idReparacion);
  }

  volver() {
    this.formModificar = false;
    this.tablaReparaciones = true;
  }

  modificar() {
    // console.log(this.reparacionSeleccionado.idReparacion);
    this.http.post('https://likeiphone.com.ar/api/modificarReparacion.php', this.reparacionSeleccionado
    ).subscribe((result: any) => {
      this.resultado = result.res;
      if (this.resultado == true) {
        this.formModificar = false;
        setTimeout(() => {
          this.resultado = false;
          this.tablaReparaciones = true;
        }, 1000);
      }
    })
  }

  ngOnInit(): void {
  }

}
