import { Component, OnInit } from '@angular/core';
import { ReparacionesService } from './reparaciones.service';

@Component({
  selector: 'app-reparaciones',
  templateUrl: './reparaciones.component.html',
  styleUrls: ['./reparaciones.component.css']
})
export class ReparacionesComponent implements OnInit {

  dolarArg=0;

  constructor(public reparaciones: ReparacionesService) {

    this.reparaciones.getJson('https://dolarapi.com/v1/dolares/blue').subscribe((res:any) => {
      this.dolarArg=parseFloat(res['venta'])+40;
    });

    this.reparaciones.getReparaciones('https://likeiphone.com.ar/api/traerReparaciones.php').subscribe((res: any) => {
      this.reparaciones = res;
    });



  }

  ngOnInit(): void {
  }

  public filtrarArreglo(tipoArreglo: string, reparaciones) {
    let items = 0;
    for (let index = 0; index < reparaciones.length; index++) {
      if (reparaciones[index].tipoArreglo == tipoArreglo) {
        items++;
      }
    }
    return items;
  }

}
